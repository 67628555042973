<template>
  <div class="outter_drawer">
    <el-drawer
      :title="drawerObj.title"
      :visible.sync="drawerObj.drawer"
      :append-to-body="true"
      :destroy-on-close="true"
      :size="drawerObj.size"
      :wrapperClosable="false"
      @close="f_close">
      <div class="outter_inner_box">
        <slot></slot>
        <slot name="inner"></slot>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
    components:{},
    props:{
      drawerObj:{
        type:Object,
        default:() => {},
      }
    },
    data(){
        return {}
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
      // 关闭抽屉
      f_close(){
        this.$emit('close')
      }
    },
}
</script>

<style lang="scss">
// 左侧弹窗样式
.el-drawer__header{
      &>:first-child{
          font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
          font-weight: 700;
          font-style: normal;
          font-size: 16px;
      }
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
      padding: 20px 20px !important;
      margin-bottom: 2px !important;
  }

</style>