<template>
    <el-tabs v-model="activeName" @tab-click="f_handleClick" >
        <el-tab-pane v-for="item in tabsList" :key="item.id" :name="item.id">
            <div slot="label">
                    <el-button size="small">{{item.name}}</el-button>
            </div>
        </el-tab-pane>

    </el-tabs>
</template>

<script>
export default {
    components:{},
    props:{
        tabsList:{
            type:Array,
            default:()=>[]
        },
        value:{
            type:String,
            default:''
        }
    },
    data(){
        return {
          activeName:this.value,
        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 切换tabs
        f_handleClick(tab, event) {
            console.log(tab, event);
            this.$emit("tabsEmit",tab.name)
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep.el-badge__content.is-fixed{
    top: 10px;
}
// ::v-deep.el-tabs__nav-wrap::after{
//     height: 0 !important;
// }
::v-deep .el-button--small, .el-button--small.is-round{
    border: none !important;
}
</style>