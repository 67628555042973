<template>
  <div :class="{'my_tip':true,'dragDialogCopy':!myDialogObj.modal}" >
    <el-dialog :title="myDialogObj.title||'提示'"
                :top="myDialogObj.top||'10vh'"
                :custom-class="myDialogObj.class"
                v-dialogDrag
                :modal-append-to-body="myDialogObj.append==undefined?true:myDialogObj.append"
                :modal="myDialogObj.modal==undefined?true:myDialogObj.modal"
                :append-to-body="myDialogObj.body==undefined?true:myDialogObj.body"
                v-if="myDialogObj.dialogVisible"
               :visible.sync="myDialogObj.dialogVisible"
               :width="myDialogObj.width||'560px'"  :close-on-click-modal="false" :destroy-on-close="true" @close="f_clear">
       <slot></slot> 
    </el-dialog>
  </div>
</template>

<script>
export default {
    components:{},
    props:{
        myDialogObj: {
            type: Object,
            default:() => {},
        }
    },
    data(){
        return {
          
        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        f_clear () {
            this.$emit('clear', false);
        },
    },
}
</script>

<style lang="scss" scoped>
.dragDialogCopy{
    ::v-deep.el-dialog__wrapper{
        width: 600px;
        height: 0px;
        left: calc(50% - 300px);
        top:18%;
        bottom: none;
        overflow: inherit;
    }
}
::v-deep .el-dialog{
    // width: 560px;
    .el-dialog__header{
        background-color: #F2F2F2;
        font-weight: bold;
        border-bottom: 1px solid #E4E4E4;
        .el-dialog__title{
            color: #333333;
        }
        .el-dialog__headerbtn{
            .el-dialog__close{
            color: #666666;
            font-weight: bold;
            font-size: 16px;
            }  
        }
    }
    .el-dialog__body{
        padding: 10px 20px;
    }
    .el-dialog__footer{
        border-top: 1px solid #E4E4E4;
    }
}
::v-deep.noClose{
    .el-dialog__headerbtn{
        display: none;
    }
}
::v-deep.noHeader{
    .el-dialog__header{
        display: none;
    }
}
</style>