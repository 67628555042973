<template>
	<div class="close-btn"></div>
</template>
<script>
	export default {
		created() {},
		mounted() {},
		data() {
			return {};
		},
		computed: {},
		methods: {},
		components: {}
	};
</script>
<style scoped>
	.close-btn {
		display: inline-block;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		width: 0.18em;
		height: 0.18em;
		position: relative;
		border: none;
		-webkit-border-radius: 1em;
		border-radius: 1em;
		font: normal 8em / normal Arial, Helvetica, sans-serif;
		color: rgba(0, 0, 0, 1);
		-o-text-overflow: clip;
		text-overflow: clip;
		background: #bc2f19;
		-webkit-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
	}
	.close-btn::before {
		display: inline-block;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		width: 0.11em;
		height: 0.025em;
		position: absolute;
		content: "";
		top: 0.07em;
		left: 0.03em;
		border: none;
		font: normal 100% / normal Arial, Helvetica, sans-serif;
		color: rgba(0, 0, 0, 1);
		-o-text-overflow: clip;
		text-overflow: clip;
		background: #ffffff;
		text-shadow: none;
	}

	.close-btn::after {
		display: inline-block;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		width: 0.11em;
		height: 0.025em;
		position: absolute;
		content: "";
		top: 0.07em;
		left: 0.03em;
		border: none;
		font: normal 100% / normal Arial, Helvetica, sans-serif;
		color: rgba(0, 0, 0, 1);
		-o-text-overflow: clip;
		text-overflow: clip;
		background: #ffffff;
		text-shadow: none;
		-webkit-transform: rotateZ(-90deg);
		transform: rotateZ(-90deg);
	}
</style>
