<template>
	<div class="pages">
		<el-pagination
			v-if="paginations.total>0"
			@size-change="f_sizeChange"
			@current-change="f_currentChange"
			:page-sizes="pageInationsConfig.pageSizes"
			:layout="pageInationsConfig.layout"
			:current-page="paginations.page"
			:page-size="paginations.size"
			:total="paginations.total"
		></el-pagination>
	</div>
</template>

<script>
	export default {
		props: {
			paginations: {
				type: Object,
				default: () => {
					return {
						page: 0, // 当前页
						size: 10, // 1页显示多少条
						total: 0 // 总数
					};
				}
			},
			pageInationsConfig: {
				type: Object,
				default: () => {
					return {
						layout: " prev, pager, next, sizes, jumper",
						pageSizes: [10, 20, 30, 50, 100]
					};
				}
			}
		},
		data() {
			return {
				paginationsData: {
					page: 0, // 当前页
					size: 10, // 1页显示多少条
					total: 0 // 总数
				}
			};
		},
		watch: {
			paginations: {
				handler() {
					this.paginationsData = this.paginations;
				},
				limmediate: true
			}
		},
		methods: {
			//每页回调
			f_sizeChange(val) {
				this.paginationsData.size = val;
				this.$emit("f_changPaginations", this.paginationsData);
			},
			//当前页回调
			f_currentChange(val) {
				this.paginationsData.page = val;
				this.$emit("f_changPaginations", this.paginationsData);
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep.pages {
		position: relative;
		text-align: right;
		margin-right: 0px;
		margin-top: 10px;
		bottom: 0px;
		.el-pagination__sizes{
			margin: 0 0 0 12px;
		}
		.el-pagination__jump{
			margin-left: 12px;
		}
	}
</style>