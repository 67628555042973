<template>
  <div class="multiple_img_upload">
    <div class="all_checked">
      <el-checkbox
        v-model="allChecked"
        @change="f_allCheckedChange"
        v-if="fileList.length"
        >全选</el-checkbox
      >
    </div>
    <el-checkbox-group
      v-model="checkList"
      @change="f_checklist"
    >
      <el-upload
        class="upload-demo"
        action="#"
        :before-upload="f_singleUpload"
        :http-request="upload"
        multiple
        :limit="0"
        :file-list="fileList"
        :accept="acceptType"
        :show-file-list="false"
        :on-exceed="f_handleExceed"
      >
        <el-button
          type="text"
          icon="el-icon-plus"
          >{{ text }}</el-button
        >
      </el-upload>
      <template v-if="fileList.length">
        <div
          class="box"
          v-for="(item, index) in fileList"
          :key="index"
        >
          <el-checkbox
            :label="item.id"
            v-if="
              checkSize(item.image_asset.full_size.width_pixels / item.image_asset.full_size.height_pixels) ||
              limitSize.length == 0
            "
          ></el-checkbox>
          <img
            :src="item.image_asset.full_size.url"
            alt=""
            @click="
              f_check(
                item.id,
                item,
                checkSize(item.image_asset.full_size.width_pixels / item.image_asset.full_size.height_pixels) ||
                  limitSize.length == 0,
              )
            "
          />
          <p
            :style="{ width: item.width * (112 / item.height) + 'px' }"
            :title="item.name"
            @click="
              f_check(
                item.id,
                item,
                checkSize(item.image_asset.full_size.width_pixels / item.image_asset.full_size.height_pixels) ||
                  limitSize.length == 0,
              )
            "
          >
            {{ item.name }}
          </p>
          <p
            class="check"
            :class="{
              pass:
                checkSize(item.image_asset.full_size.width_pixels / item.image_asset.full_size.height_pixels) ||
                limitSize.length == 0
                  ? false
                  : true,
            }"
            @click="
              f_check(
                item.id,
                item,
                checkSize(item.image_asset.full_size.width_pixels / item.image_asset.full_size.height_pixels) ||
                  limitSize.length == 0,
              )
            "
          >
            {{
              checkSize(item.image_asset.full_size.width_pixels / item.image_asset.full_size.height_pixels) ||
              limitSize.length == 0
                ? '已上传'
                : '图片尺寸不符合'
            }}
          </p>
        </div>
      </template>
    </el-checkbox-group>
  </div>
</template>

<script>
import { createImageAsset } from '@/api/google/ceateAdGg.js';
export default {
  props: {
    limitSize: {
      //宽高比为限定的三种尺寸
      type: Array,
      default: function () {
        return [];
      },
    },
    dataFileNum: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    // 现有数组的长度
    imgLength: {
      type: Boolean,
      default: false,
    },
    dataFileSize: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    acceptType: {
      type: String,
      default: function () {
        return '*';
      },
    },
    text: {
      type: String,
      default: function () {
        return '';
      },
    },
    type: {
      type: String,
      default: '',
    },
    accountId: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {
      fileList: [], //upload多文件数组
      checkList: [], //选中的文件ids
      allChecked: true,
      uploadData: [], //上传后的文件数组
    };
  },
  components: {},
  methods: {
    // 全选
    f_allCheckedChange(val) {
      let arr = [],
        data = [];
      if (val)
        this.fileList.map((item) => {
          if (this.checkSize(item.image_asset.full_size.width_pixels / item.image_asset.full_size.height_pixels)) {
            arr.push(item.id);
            data.push(item);
          }
        });
      this.checkList = [...arr];
      this.uploadData = [...data];
      this.$emit('uploadData', this.uploadData);
    },
    // 单个上传
    f_singleUpload(raw) {
      var fd = new FormData();
      fd.append('files', raw);
      fd.append('accountId', this.accountId.toString());
      this.$showLoading();
      createImageAsset(fd).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.fileList = this.fileList.concat(res.data);
          let d = res.data;
          if (
            d &&
            d.length > 0 &&
            this.checkSize(d[0].image_asset.full_size.width_pixels / d[0].image_asset.full_size.height_pixels)
          ) {
            this.checkList = this.checkList.concat([d[0].id]);
            this.uploadData = this.uploadData.concat(d);
            this.$emit('uploadData', this.uploadData);
          }
        }
      });
      return false;
    },
    // 判断比例是否合适
    checkSize(size) {
      return this.limitSize.includes(size.toFixed(2));
    },
    // 多选
    f_checklist() {
      console.log(this.checkList);
    },
    f_check(id, v, type) {
      if (!type) return;
      if (this.checkList.includes(id)) {
        let num = -1;
        this.checkList.map((item, k) => {
          if (item === id) num = k;
        });
        if (num > -1) {
          this.checkList.splice(num, 1);
          this.uploadData.splice(num, 1);
        }
      } else {
        this.checkList.push(id);
        this.uploadData.push(v);
      }
      this.$emit('uploadData', this.uploadData);
    },
    // 个数限制
    f_handleExceed(files, fileList) {
      console.log(this.dataFileNum, '限制个数');
      this.$message.warning(
        `当前限制选择 ${this.dataFileNum} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/google/materials.scss';
$uploadHeight: 112px;
$bgColor: #f5f5f5;
.multiple_img_upload {
  padding: 10px;
  .all_checked {
    margin-left: 20px;
  }
  ::v-deep.upload-demo {
    width: $uploadHeight;
    height: $uploadHeight;
    text-align: center;
    line-height: $uploadHeight;
    margin: 10px 10px 0px 10px;
    .el-upload {
      width: 100%;
      height: 100%;
      .el-button {
        width: 100%;
        height: 100%;
        border: 1px dashed #66aefe;
        i {
          font-size: 28px;
        }
      }
    }
  }
}
</style>
